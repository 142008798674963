<template>
  <WebAppLayout>
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col cols="12" sm="6" md="5" lg="4" xl="3" xxl="2">
          <MalarkeyLogin v-bind="malarkeyLoginBindings" />
        </v-col>
      </v-row>
    </v-container>
  </WebAppLayout>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, inject } from "vue";
import WebAppLayout from "../components/webAppLayout.vue";

import type { LocalStorageService } from "@jakguru/vueprint";

export default defineComponent({
  name: "UnauthenticatedLayout",
  components: {
    WebAppLayout,
  },
  setup() {
    const malarkey = useUseMalarkeyConfig();
    const route = useRoute();
    const ls = inject<LocalStorageService>("ls");

    const type = computed(() => {
      if (ls) {
        const lastLoginType = ls.get("loginType");
        switch (lastLoginType) {
          case "agent":
            return "agent";
          case "client":
            return "client";
        }
      }
      return route.name === "login-agent" ? "agent" : "client";
    });

    const malarkeyLoginBindings = computed(() => ({
      type: type.value,
      logo: malarkey.visualFiles.logoColor,
      appName: malarkey.customizations.app.name,
      brandName: malarkey.customizations.brand.name,
    }));

    onMounted(() => {
      if (ls) {
        const typeToSet = route.name === "login-agent" ? "agent" : "client";
        ls.set("loginType", typeToSet);
      }
    });

    return {
      malarkeyLoginBindings,
    };
  },
});
</script>
